import { useEffect } from "react";
import RoutesProvider from "./routes";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setLang } from "./data/slices/settingsSlice";
import { Helmet } from "react-helmet";

function App() {
  const dispatch = useDispatch();

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    dispatch(setLang(currentLanguage.dir || "ltr"));
    document.title = t("app_title");

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", t("app_description"));
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = t("app_description");
      document.head.appendChild(newMetaDescription);
    }
  }, [currentLanguage, t]);

  return (
    <>
      <Helmet>
        <title>{t("app_title")}</title>
        <meta name="description" content={t("app_description")} />
        <meta property="og:title" content={t("app_title")} />
        <meta property="og:description" content={t("app_description")} />
        <meta
          property="og:locale"
          content={currentLanguageCode === "ar" ? "ar_AR" : "en_US"}
        />
      </Helmet>
      <RoutesProvider />
    </>
  );
}

export default App;
