import "./_Logo.scss";
import cookies from "js-cookie";
import arabicLogo from "../../assets/icons/arabicLogo.png";
import englishLogo from "../../assets/icons/englishLogo.png";

function Logo() {
  const currentLanguageCode = cookies.get("i18next") || "ar";

  return (
    <a
      className="header-logo-container"
      style={{ cursor: "pointer" }}
      target="_blank"
      href="https://Jawlah.com.sa"
    >
      <img
        className="img_logo"
        src={currentLanguageCode === "en" ? englishLogo : arabicLogo}
        alt=""
      />
    </a>
  );
}

export default Logo;
