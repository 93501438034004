import React from "react";
import "./ProducedBy.css";
import callIcon from "../../assets/icons/call-icon.svg";
import whatsappIcon from "../../assets/icons/whatsapp-floating.svg";
import websiteIcon from "../../assets/icons/website-icon.svg";
import { useTranslation } from "react-i18next";

const ProducedBy = () => {
  const { t } = useTranslation();
  return (
    <div className="produced-by-container">
      <div className="phone-link">
        <p>{t("produced_by.produced_by_text")}</p>
        <a className="callIconLink" href="tel:+966920025227">
          <img src={callIcon} alt={callIcon} /> <span>920025227</span>
        </a>
        <a
          className="callIconLink whastapp-con"
          target="_blank"
          rel="noopener noreferrer"
          href="https://wa.me/message/U6SOUKZEXBMDI1"
        >
          <img src={whatsappIcon} alt={whatsappIcon} />
        </a>
      </div>
      <div className="web-link">
        <a
          className="websiteIconLink"
          target="_blank"
          href="https://Jawlah.com.sa"
        >
          <span>Jawlah.com.sa</span>
          <img src={websiteIcon} alt={websiteIcon} />
        </a>
      </div>
    </div>
  );
};

export default ProducedBy;
