import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import ProducedBy from "../../components/ProducedBy/ProducedBy";

const MainLayout = () => {
  return (
    <div className="main_layout">
      {/* <Sidebar /> */}
      <div className="main_layout__container">
        <Header />
        <Outlet />
        <ProducedBy />
        {/* <Footer /> */}
      </div>
      <Backdrop />
    </div>
  );
};

export default MainLayout;
